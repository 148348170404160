import './vendors/h5bp';


/* 
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
function goMaterialize() {
  $('.slider').slider({height:500});
  $(".button-collapse").sideNav();
}


function fancyBoxes () {
  $(".htmlpopup").fancybox({
    'width':600,
    'autoSize' : false
  });
}

/*
 * 
 * Ejecuto el llamado de todas las funciones Nac y Pop
 *
 */
$(document).ready(function(){

  goMaterialize();
  fancyBoxes();

});
